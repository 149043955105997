html body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    color: var(--base-color)
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
    padding: 0;
    font-weight: normal;
}

body ol,
body ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

:root {
    --green-bg: #5C8B3B;
    --white-bg: #ffffff;
    --black-bg: #000000;
    --white-border: 255, 255, 255;
    --gray-bg: #999999;
    --gray-bg-800: #888888;
    --ct-box-shadow: 0px 0px 35px 0px rgba(154, 161, 171, 0.15);
    --green-border: 40, 101, 15;
    --dark-color: #666;
    --green-border-500: #00875A;
    --green-text: #28650F;
    --dark-green: #436B26;
    --dark-green-rgb: 67, 107, 38;
    --black-color-333: #333333;
    --green-light: #63AB45;
    --btn-color: #5FC634;
    --base-color: #666666;
    --light-green: #00B167;
    --gray-light: #D1D1D1;
}

/*=============================================================================================================*/

/*TOP HEAD CSS*/

.top-head {
    background: var(--green-bg);
    position: relative;
    padding: 0 7rem 0 5rem;
}

.menu-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: 1px solid rgba(var(--white-border), 0.1);
    width: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-icon a {
    color: var(--white-bg);
    font-size: 2.8rem;
    text-decoration: none;
}

.top-left-details a {
    color: var(--white-bg);
    font-size: 1.75rem;
    text-decoration: none;
    font-weight: 700;
}

.live-chat a {
    display: inline-block;
    width: 6rem;
}

.lang-icon {
    position: absolute !important;
    right: 0;
    top: 0;
    bottom: 0;
    border-left: 1px solid rgba(var(--white-border), 0.1);
    width: 6.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lang-icon a.dropdown-toggle {
    color: var(--white-bg);
    font-size: 2.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.lang-icon a.dropdown-toggle img {
    width: 2.5rem;
}

.lang-icon .dropdown-toggle::after {
    border-top: 0.4rem solid;
    border-right: 0.4rem solid transparent;
    border-bottom: 0;
    border-left: 0.4rem solid transparent;
}

/*LOGO SEARCH CART CSS*/

.dropdown-menu-animated {
    -webkit-animation-name: DropDownSlide;
    animation-name: DropDownSlide;
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    position: absolute;
    margin: 0;
    z-index: 1000;
    box-shadow: var(--ct-box-shadow);
}

.dropdown-menu-animated.show {
    top: 100% !important;
}

.site-logo a {
    max-width: 190px;
    display: inline-block;
}

.search-form {
    width: 55px;
    height: 55px;
    background: var(--white-bg);
    line-height: 55px;
    padding-right: 15px;
    border-radius: 100px;
    transition: all 0.5s ease;
    position: relative;
    border: 1px solid rgba(var(--green-border), 0.1)
}

.input-control {
    border: 0;
    background: transparent;
    width: 0%;
    outline: none;
    font-size: 1rem;
    font-style: italic;
    transition: all 0.3s ease;
    position: relative;
}

.search-form .bi {
    color: rgba(var(--green-border), 0.5);
    position: absolute;
    left: 17px;
    top: 0;
    font-size: 22px;
    cursor: pointer;
    bottom: 0;
}

.search-form.active {
    width: 100%;
    padding-left: 45px;
    transition: all 0.5s ease;
}

.input-control.active {
    width: 98%;
    padding-left: 5px;
    transition: all 0.5s 0.8s ease;
}

.site-title h1 {
    background: linear-gradient(180deg, #436B26 52.78%, rgba(161, 181, 147, 0.50) 76.39%, rgba(255, 255, 255, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    font-size: 2.375rem;
    font-weight: 700;
}

input.input-control::placeholder {
    color: var(--dark-color);
}

.book-now-box a {
    display: inline-block;
    background: var(--green-border-500);
    padding: 8px 15px;
    font-size: 1.25rem;
    text-transform: uppercase;
    text-decoration: none;
    color: var(--white-bg);
    font-weight: 700;
}

.cart-box a {
    background: rgba(var(--green-border), 0.12);
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    position: relative;text-decoration: none;
}

.cart-box a i {
    font-size: 2.5rem;
    color: var(--green-text);
   
}

.cart-box a span {
    position: absolute;
    font-weight: 600;
    font-size: 0.938rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--green-text);
}

/*NAVIGATION CSS*/

.navigation-section nav.navbar {
    background: var(--green-bg);
}

.navigation-section nav.navbar ul li a.nav-link {
    color: #fff;
    font-size: 1.6875rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    border-right: 2px solid var(--dark-green);
}

.navigation-section nav.navbar ul li:last-child a.nav-link {
    border-right: none
}

.navigation-section nav.navbar ul li a.nav-link:hover {
    background: var(--dark-green);
}

.navigation-section nav.navbar ul li.active a.nav-link {
    background: var(--dark-green);
}

.navigation-section nav.navbar ul li .dropdown-menu {
    background: var(--green-bg);
    border: none;
    padding: 0;
    overflow: hidden
}

.navigation-section nav.navbar ul li .dropdown-menu li a.dropdown-item {
    font-size: 1.5rem;
    color: var(--white-bg);
    font-weight: 600;
    padding: 0.75rem
}

.navigation-section nav.navbar ul li .dropdown-menu li a.dropdown-item:hover {
    background: var(--dark-green);
}

.lang-icon .dropdown-menu .dropdown-item:hover {
    background-color: var(--green-bg);
    color: #fff;
}

button.navbar-toggler {
    border: none;
    padding: 0;
    outline: inherit
}

/*VIDEO CSS*/

.video-section {
    height: 75vh;
    width: 100%;
}

.video-section video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*SECTION SPACE*/

.section-equal-space {
    padding: 20px;
    overflow: hidden;
    background-color: #ffffffe3;
    border-radius: 15px;
}

/* .section-equal-space .container-fluid {
    max-width: 1430px;
    width: 100%;
} */

/*WELCOME CONTENT CSS*/

.bg-img {
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover
}

.welcome-content h2 {
    font-weight: 700;
    line-height: 1.4;
    font-size: 2.0625rem;
    color: var(--black-color-333);
}

/*SECTION TOP HEADING CSS*/

.section-top-heading {
    position: relative;
}

.section-top-heading h2 {
    font-size: 2.8125rem;
    font-weight: 700;
    display: inline-block;
    background: #fff;
    padding: 0 1.25rem;
    position: relative;
}

.section-top-heading h2 span {
    background: var(--dark-green);
    color: var(--white-bg);
    padding: 0.5rem 2rem;
    border-radius: 4rem;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.section-top-heading:before {
    height: 2px;
    position: absolute;
    content: "";
    width: 9999px;
    left: -5000px;
    background: var(--green-text);
    top: 0;
    bottom: 0;
    margin: auto;
}

/*CART FIGURE CSS*/

.card-figure-box {
    border: 1px solid var(--green-light);
    padding: 0.75rem;
    border-radius: 0.75rem
}

.card-figure img {
    border-radius: 0.75rem;
    height: 300px;
    width: 100%;
    object-fit: cover;
}

.card-figure {
    position: relative;
}

span.top-span {
    background: var(--dark-green);
    font-weight: 600;
    color: var(--white-bg);
    padding: 0.2rem 0.75rem;
    display: inline-block;
    font-size: 1.5rem;
    border-radius: 40px;
    position: absolute;
    left: 10px;
    top: 10px;
}

.card-body-content h3 {
    font-weight: 700;
    color: var(--black-color-333);
    margin: 0 0 1rem;
}

.rating-box i {
    color: var(--dark-green);
    font-size: 1.125rem;
}

.rating-box p {
    font-weight: 500;
    font-size: 0.8125rem;
    color: var(--green-text);
}

/*DEATILS MORE BUTTON CSS*/

a.more-btn {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid var(--btn-color);
    padding-right: 70px;
    text-decoration: none;
    color: var(--btn-color);
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 50px;
    position: relative;
    transition: all 0.7s
}

a.more-btn:hover {
    background: var(--dark-green);
    color: var(--white-bg);
    border-color: var(--dark-green);
}

a.more-btn i.bi {
    background: var(--btn-color);
    height: 35px;
    width: 35px;
    position: absolute;
    right: 6px;
    top: 6px;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-bg);
}

/*VIEW ALL BUTTON CSS*/

a.view-all-btn {
    display: inline-block;
    padding: 10px 20px;
    border: 1px solid var(--dark-green);
    padding-right: 70px;
    text-decoration: none;
    color: var(--white-bg);
    font-size: 1.125rem;
    text-transform: uppercase;
    font-weight: 700;
    border-radius: 50px;
    position: relative;
    transition: all 0.7s;
    background: var(--dark-green);
}

a.view-all-btn-hover {
    display: inline-block;
    border: 2px solid var(--green-bg);
    padding: 12px 30px 9px;
     font-weight: 700;
    text-decoration: none;
    color: #5FC634; 
    text-transform: uppercase; 
    border-radius: 50px;
    position: relative;
    transition: all 0.7s;
    background: 'white';
}

.book-now:hover {
    background-color: #636363;
    color: #fff;
}

a.view-all-btn-hover:hover { 
    color: #4CBB17; 
 
}

a.view-all-btn:hover {
    background: var(--btn-color);
    color: var(--white-bg);
    border-color: var(--btn-color)
}

a.view-all-btn i.bi {
    background: var(--btn-color);
    height: 35px;
    width: 35px;
    position: absolute;
    right: 6px;
    top: 6px;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white-bg);
}

/*GREEN LIGHT BACKGROUND CSS*/

.green-light-bg {
    background: rgba(var(--dark-green-rgb), 0.05)
}

.green-light-bg .section-top-heading h2 {
    background: #f5f7f4;
}


/* HAVE A QUERY BOX CSS */

.query-box {
    background: rgba(var(--dark-green-rgb), 0.05);
    padding: 2.5rem;
    border-radius: 1rem;
    border: 1px solid var(--dark-green);
}

.query-head h2 {
    font-size: 3.125rem;
    font-weight: 700;
    color: var(--dark-green);
}

/* HAVE A QUERY BOX BUTTON CSS */

.action-button a {
    border: 1px solid var(--dark-green);
    padding: 0.3rem 25px;
    display: inline-block;
    text-decoration: none;
    color: var(--dark-green);
    background: #fff;
    font-size: 1.3125rem;
    font-weight: 700;
    border-radius: 6rem;
    margin: 0 0 10px 0;
    text-transform: uppercase;
}

.action-button a:hover {
    background: var(--dark-green);
    color: var(--white-bg)
}

.action-button a.live-chat-btn {
    padding-right: 4rem;
    position: relative;
    background: var(--dark-green);
    color: var(--white-bg);
}

.action-button a.live-chat-btn i {
    background: var(--white-bg);
    height: 35px;
    width: 35px;
    position: absolute;
    right: 4px;
    top: 3px;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-green);
}

.action-button a.live-chat-btn:hover {
    background: var(--green-bg);
    border-color: var(--green-bg);
}


/* ADVENTURES ITEM CSS */

.adventures-block figure {
    display: inline-block;
    width: 190px;
    height: 190px;
}

.adventures-block figure img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100px;
}

.adventures-block p {
    font-size: 1.125rem;
    margin: 0 0 1rem;
}

.outdoor-adven h2 {
    color: #000;
    font-size: 2.8125rem;
    font-weight: 700;
}

.owl-carousel .owl-nav {
    text-align: center;
    margin: 2.5rem 0 0
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
    border: 1px solid var(--dark-green);
    height: 50px;
    width: 50px;
    border-radius: 100px;
    font-size: 2rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--dark-green);
    margin: 0 0.8rem 0 0;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
    background: var(--dark-green);
    color: var(--white-bg)
}


/**TESTIMONIALS CSS**/

.testimonial-title h2 {
    font-size: 2.8125rem;
    font-weight: 700;
    color: var(--black-bg);
}

.slide-item {
    border: 1px solid rgba(var(--green-border), 0.18);
    border-radius: 1rem;
    padding: 2rem;
}

.slide-item figure img {
    max-width: 150px;
    height: 150px;
    border-radius: 100px;
}

.slide-item h3 {
    color: var(--black-color-333);
    font-weight: 600;
    font-size: 1.375rem;
}

.slide-item h3 span {
    color: var(--dark-green);
}

.slide-item h4 {
    font-weight: 400;
    font-size: 1rem;
    color: var(--black-bg);
}

/**FOOTER CSS**/

.footer-section {
    background: #222
}

.footer-section .container-fluid {
    max-width: 1800px;
    width: 100%;
}

.footer-navigation {
    border-bottom: 1px solid rgba(var(--white-border), 0.10);
    /* background-image: url(../images/shape-5.png); */
}

.footer-columns {
    border-right: 1px solid rgba(var(--white-border), 0.10);
}

.footer-heading {
    margin-bottom: 1.5rem;
}

.footer-heading h3 {
    font-size: 29px;
    font-weight: 700;
    color: var(--light-green);
}

.footer-links ul.nav-footer {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
}

.footer-links ul li {
    margin-bottom: 0.75rem
}

.footer-links ul li a {
    text-decoration: none;
    color: var(--gray-bg);
}

.footer-links ul li a:hover {
    color: var(--green-light)
}

/*FOOTER BUTTON LIVE & ORDER*/

.footer-btn a {
    width: 100%;
    display: inline-block;
    border: 1px solid var(--gray-bg);
    color: var(--gray-bg);
    padding: 0.75rem 4rem 0.75rem 0.9375rem;
    font-size: 1.5625rem;
    text-decoration: none;
    font-weight: 700;
    border-radius: 0.5rem;
    margin-top: 1.25rem;
    position: relative;
}

.footer-btn a i {
    position: absolute;
    right: 1rem;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 2rem;
}

.footer-btn a:hover {
    background: var(--green-light);
    border-color: var(--green-light);
    color: var(--white-bg)
}


/* FOOTER SOCIAL ICONS CSS */

.social-icons ul li a {
    padding-left: 2rem;
    position: relative;
}

.social-icons ul li a i {
    position: absolute;
    left: 0;
    width: 1.375rem;
    height: 1.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-bg);
    color: var(--white-bg);
    border-radius: 4px;
    font-size: 14px;
}

.social-icons ul li.facebook:hover a {
    color: #1877F2;
}

.social-icons ul li.facebook:hover a i {
    background: #1877F2;
}

.social-icons ul li.twitter:hover a {
    color: #1DA1F2;
}

.social-icons ul li.twitter:hover a i {
    background: #1DA1F2;
}

.social-icons ul li.linkedin:hover a {
    color: #0077b5;
}

.social-icons ul li.linkedin:hover a i {
    background: #0077b5;
}

.social-icons ul li.youtube:hover a {
    color: #CD201F;
}

.social-icons ul li.youtube:hover a i {
    background: #CD201F;
}

.social-icons ul li.instagram:hover a {
    color: #FCAF45;
}

.social-icons ul li.instagram:hover a i {
    background: #FCAF45;
}

.social-icons ul li.pinterest:hover a {
    color: #E60023;
}

.social-icons ul li.pinterest:hover a i {
    background: #E60023;
}

.social-icons ul li.rss:hover a {
    color: #f26522;
}

.social-icons ul li.rss:hover a i {
    background: #f26522;
}



/*LOGIN BUTTON CSS*/

.login-button a {
    display: inline-block;
    border: 1px solid var(--gray-bg);
    color: var(--gray-bg);
    padding: 0.5rem 2rem 0.5rem;
    font-size: 1.5625rem;
    text-decoration: none;
    font-weight: 700;
    border-radius: 0.5rem;
    margin-top: 1.25rem;
    position: relative;
    text-transform: uppercase;
}

.login-button a:hover {
    background: var(--green-light);
    border-color: var(--green-light);
    color: var(--white-bg)
}

/*WHY CHOOSE US CSS*/



.footer-heading h4 {
    color: var(--light-green);
    font-size: 1.25rem;
    font-weight: 600;
}

.goal-list h4 {
    font-size: 1.5625rem;
    font-weight: 700;
    color: var(--gray-light);
    margin: 0 0 1rem;
}

.goal-list ul li {
    margin-bottom: 0.75rem;
    padding-left: 2rem;
    position: relative;
    color: var(--gray-bg);
}

.goal-list ul li i {
    position: absolute;
    left: 0;
    top: -6px;
    font-size: 1.5rem;
    color: var(--light-green);
}

/*FOOTER NAV CSS*/

.footer-nav-links ul li a {
    font-size: 1.5rem;
    color: var(--light-green);
    font-weight: 600;
    text-decoration: none;
    padding: 0 1.5rem 0;
    position: relative;
}

.footer-nav-links ul li a:after {
    content: "";
    position: absolute;
    height: 1.25rem;
    width: 3px;
    background: var(--white-bg);
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
}

.footer-nav-links ul li:last-child a:after {
    display: none;
}



/*COPYRIGHT CSS*/

.copyright-section p {
    font-size: 1.5625rem;
    font-family: 'Domine', serif;
    color: #888;
}
.owl-dots {
    display: none;
}
.lang-icon .dropdown-menu a+a {
    border-top: 1px solid #ccc;
}
.yourModalWrap {
    border: 1px solid rgb(92, 139, 59);
    border-radius: 0 0 10px 10px;
        padding: 15px;
}
.navitmeImg {
    max-width: 230px;
    margin-right: 30px;
}
.view-all-btn-hover .bi-arrow-right {
    background-color: rgb(0, 100, 0);
    color: #fff;
    width: 24px;
    height: 24px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    font-size: 17px;
}
.navitmeBtnBlock  .more-btn {
    border-color: var(--dark-green); 
    color: #006400;
}
.navitmeBtnBlock .more-btn i.bi{
background-color: var(--dark-green);
}
.navitmeBtnBlock .bi-arrow-right::before {
    font-weight: 900 !important;
}
.navitmeBtnBlock .more-btn:hover i.bi {
    background-color: var(--white-bg);
    color: var(--dark-green);
}
.navitmeBtnBlock .more-btn:hover {
    color: rgb(255, 255, 255);
}
.navitmeBtnBlock .view-all-btn-hover:hover{
    background-color: var(--dark-green);
border-color: var(--dark-green);
color: rgb(255, 255, 255);
}
h4.navitmeTitle {
    font-weight: 600;
}
header.header-section {
    position: relative;
}
.megaMenuBlock {
    position: absolute;
    top: 100%;
    max-width: 92.2vw;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    padding-top: 20px;
    z-index: 99;
}

#navbarSupportedContent a.nav-link {
    position: relative;
}

#navbarSupportedContent a.nav-link:before {
    border-bottom: 20px solid #ffffffe3;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent; 
    content: "";
    position: absolute;
    top: 100%;
    width: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
}
#navbarSupportedContent li:hover a.nav-link:before { 
    opacity: 1;
}
.megaMenuBlock h4.megaMenuBlockTitle {
    background-color: rgb(92, 139, 59);
    padding: 10px 30px;
    margin: 0px;
    color: white;
    border-radius: 10px 10px 0px 0px;
}
.VisibleOnlyXs {
    display: none;
}
.navigation-section button.navbar-toggler {
    padding: 10px; 
}
.navigation-section button.navbar-toggler:focus { 
    box-shadow: none;
}
.navigation-section button.navbar-toggler  .navbar-toggler-icon {
    background-image: url(/src/images/toggleIcon.svg);
}
.live-chat a:hover {
    filter: grayscale(1);
}
.footer-nav-links ul li a:hover {
    color: var(--btn-color);
}
/*  css media */
@keyframes DropDownSlide {

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    0% {
        -webkit-transform: translateY(30px);
        transform: translateY(30px);
    }


}

@media(min-width:320px) and (max-width:575px) {
.yourModalWrap .list-item .navitmeImg {
    margin: 0 0 10px 0;
    max-width: 100%;
    
}
.VisibleOnlyXs {
    display: block;
}
.HiddenOnlyXs{
    display: none;
}
}
@media(min-width:576px) and (max-width:767px) {

.navitmeImg { 
    margin-right: 15px;
    margin-bottom: 15px;
    max-width: 180px;
}
.navitmeInfo {
    flex: 1 0 0%;
}
h4.navitmeTitle {
    font-weight: 600;
    font-size: 20px;
}

.navigation-section nav.navbar .dropdown-toggle::after {
    content: "\f285";
    border: none;
    font-family: bootstrap-icons!important;
    position: absolute;
    right: 15px;
    font-size: 20px;
    top: 50%;
    margin: -14px 0 0;
    transition: 0.3s;
}
.navigation-section nav.navbar .dropdown-toggle.show::after {
    transform: rotate(90deg);
}
}

@media(min-width:320px) and (max-width:767px) {
.megaMenuBlock {
    position: relative;
    padding: 0px;
    max-width: 100%;
   
    
    
    transition: 0.5s;
    min-height: 0;
}
.yourModalWrap {
    border: none; 
    padding-top: 0;
}
.section-equal-space {
    padding: 0; 
    border-radius: 0;
}
.megaMenuBlock h4.megaMenuBlockTitle {
    background-color: transparent;
    padding: 10px 15px;
    margin: 0px;
    border-radius: 0;
    color: rgb(92, 139, 59);
    font-size: 20px;
    text-align: center;
}
#navbarSupportedContent a.nav-link:before{
    content: none;
}
.yourModalWrap .list-item {
    flex-wrap: wrap;
}
.yourModalWrap .list-item {
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding-bottom: 20px !important;
}
.navitmeBtnBlock a.view-all-btn-hover {
    padding: 6px 20px 3px;
    margin-right: 10px !important;
    margin-bottom: 10px;
    border-width: 1px;
    font-weight: 500;
}
.navitmeBtnBlock .more-btn {
    padding: 3px 40px 3px 20px;
    font-weight: 500;
}
.navitmeBtnBlock .more-btn i.bi.bi-arrow-right {
    width: 24px;
    height: 24px;
    font-size: 15px;
    top: 5px;
}
.yourModalWrap .navitmeImg + h4.navitmeTitle {
    flex: 1 0 0%;
    align-self: flex-start;
    font-size: 20px;
}
.show + .megaMenuBlock {
    height: auto;
    min-height: 100px;
    transition: 0.5s;
}
}
@media(min-width:768px) and (max-width:991px) {
.navitmeImg { 
    margin-right: 15px;
}
.navitmeBtnBlock a.view-all-btn-hover {
    padding: 6px 20px 3px;
    margin-right: 10px !important;
    margin-bottom: 10px;
    border-width: 1px;
    font-weight: 500;
}
.navitmeBtnBlock .more-btn {
    padding: 3px 40px 3px 20px;
    font-weight: 500;
}
.navitmeBtnBlock .more-btn i.bi.bi-arrow-right {
    width: 24px;
    height: 24px;
    font-size: 15px;
    top: 5px;
}
h4.navitmeTitle { 
    font-size: 20px;
}
.megaMenuBlock {
    position: relative; 
    padding-top: 0; 
    max-width: 100%;
    height: 0;
    overflow: hidden;
    transition: 0.5s;
    min-height: 0;
}
.section-equal-space { 
    border-radius: 0;
}
#navbarSupportedContent a.nav-link:before{
    content: none;
}
.show + .megaMenuBlock {
    height: auto;
    min-height: 100px;
    transition: 0.5s;
}
}

@media(min-width:1300px) and (max-width:1420px) {
    .section-equal-space .container-fluid {
        padding: 0 50px;
    }

}



@media(max-width:991px) {
    .navigation-section nav.navbar a.navbar-brand {
        width: 90px;
        padding: 1px;
    }

    .navigation-section nav.navbar ul li a.nav-link {
        font-size: 1rem;
        padding: 0.7rem;
        border-bottom: 1px solid var(--dark-green);
        border-right: 0;
    }

    .navigation-section nav.navbar ul li:last-child a.nav-link {
        font-size: 1rem;
        padding: 1rem;
        border-bottom: 0;
    }

    .navigation-section nav.navbar ul li .dropdown-menu li a.dropdown-item {
        font-size: 1rem;
    }

}

@media(max-width:767px) {

    .top-head {
        padding: 0 4rem 0 3rem;
    }

    .menu-icon {
        width: 2.5rem;
    }

    .menu-icon a {
        font-size: 1.5rem;
    }

    .search-form {
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 9;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .top-left-details a {
        font-size: 1.5rem
    }

    .lang-icon {
        width: 3.5rem
    }

    .lang-icon a.dropdown-toggle img {
        width: 1.5rem;
    }

    .mobile-search-view {
        position: relative;
    }

    .search-form .bi {
        left: 0.5rem
    }

    .welcome-content h2 {
        font-size: 1.25rem
    }

    .section-top-heading h2 {
        font-size: 1.5rem
    }

    .query-head h2,
    .outdoor-adven h2,
    .testimonial-title h2 {
        font-size: 2rem
    }

    .action-button a {
        margin: 0 0 1rem;
    }

    .action-button a:last-child {
        margin-bottom: 0;
    }

    .footer-nav-links ul li a {
        font-size: 1rem;
        padding: 0 1rem
    }

    .copyright-section p {
        font-size: 1rem
    }

    .site-title h1 {
        font-size: 1.25rem
    }

    .book-now-box a {
        font-size: 1rem
    }

    .cart-box {
        margin: 0 1.25rem 0 0;
    }

    .cart-box a {
        height: auto;
        width: auto;
        background: transparent;
    }

    .cart-box a i {
        font-size: 1.5rem;
    }

    .cart-box a span {
        position: absolute;
        bottom: auto;
        right: -13px;
        left: auto;
        background: #eee;
        z-index: 1;
        height: 25px;
        width: 25px;
        top: -6px;
        border-radius: 1.5rem;
    }

    .rating-box {
        flex: 0 0 100%
    }
    
}

@media(min-width:768px) and (max-width:1023px) {

    .site-title h1 {
        font-size: 2rem
    }

    .book-now-box a {
        font-size: 1rem
    }



}


@media(min-width:1024px) and (max-width:1200px) {
    .book-now-box a {
        font-size: 1rem
    }

    .navigation-section nav.navbar ul li a.nav-link {
        font-size: 1rem;
        padding: 1rem 1.25rem;
    }

    .navigation-section nav.navbar ul li .dropdown-menu li a.dropdown-item {
        font-size: 1rem;
    }
}

@media(min-width:1201px) and (max-width:1600px) {
    .book-now-box a {
        font-size: 1rem
    }

    .navigation-section nav.navbar ul li a.nav-link {
        font-size: 1.25rem;
        padding: 1rem 1.25rem;
    }

    .navigation-section nav.navbar ul li .dropdown-menu li a.dropdown-item {
        font-size: 1rem;
    }

    .china-flag {
      width: 20px;      
      height: auto;
    }

}